@use '../../styles/colours.scss' as *;
@use '../../styles/conversions.scss' as *;
@use '../../styles/mixins.scss' as *;

#c-projects {
    padding-top: 0;
    box-sizing: border-box;
    padding: 1rem;
    padding-top: 0;

    @include desktop {
        padding: 0;
        padding-top: 0.5rem;
        padding-right: 1rem;
        width: 40%;
        max-width: 35rem;
    }
}

.pl-card {

    width: 100%;
    flex-direction: column;
    color: $color-a;
    display: flex;
    flex-wrap: nowrap;
    transition: max-height 0.25s ease-in-out, box-shadow 0.25s ease-in-out;
    border-radius: 0.5rem;
    max-height: 2.5rem;
    padding: 0.5rem;
    margin: 0 auto;
    margin-bottom: 1rem;
    box-sizing: border-box;
    border: thin solid $color-g;
    cursor: pointer;
    opacity: 0.8;
    overflow: hidden;
    position: relative;

    @include tablet {
        width: 100%;
        max-height: 3rem;
        flex-direction: row;
        flex-wrap: wrap;
    }

    &-2 {
        max-height: 2.6rem;
        padding: 0.2rem;
        margin: 0 auto;
        margin-bottom: 1rem;
    }

    &:hover {
        opacity: 1;
    }

    &.expanded {
        opacity: 1;
    }

    &:hover {
        box-shadow: 0 $px5 $px30 rgba(0, 0, 0, 0.1);
        // backdrop-filter: blur($px5);
    }

    &__image {
        display: none;
        width: 20rem;
        margin-right: auto;
        top: 0;
        margin-left: -22rem;
        pointer-events: none;
        overflow: visible;
        position: fixed;
        z-index: 99;
    }

    &:hover &__image {
        display: block;
        opacity: 1;
    }

    @include desktop {

        flex-direction: row;

    }

    &:hover .projects-title-bar__link {
        animation: glow 2.5s linear infinite;
    }

    .projects-title-bar {

        flex-basis: 100%;
        display: flex;
        justify-content: space-between;
        padding: 0 0.5rem;
        align-items: center;
        z-index: 1;

        &__head {
            margin: 0;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        &__group {
            display: flex;
            flex-direction: column;
            font-size: 0.8rem;
            font-weight: 700;
            align-items: flex-end;
            margin: 0;

            &--type {
                display: none;

                @include tablet {
                    display: inline-block;
                }
            }

            &--date {
                width: 6rem;
                font-size: 0.75rem;
                color: $color-a-low-op;
                text-align: end;
                text-transform: uppercase;
            }
        }

    }

    .pl-card-links {

        width: 100%;
        display: flex;
        box-sizing: border-box;
        flex-direction: row;
        flex-basis: 50%;
        justify-content: flex-end;
        flex-grow: 2;
        padding: 0.5rem;
        align-items: flex-end;
        gap: 1rem;

        &__link {
            font-size: 0.8rem;
            color: $color-a;
            text-decoration: none;
            text-transform: uppercase;
            font-weight: 700;
        }

        &__link:hover {
            animation: bright-glow 1s ease-out;
        }

    }

    &__description {
        color: $color-c;
        box-sizing: border-box;
        // text-align: end;
        padding: 0.5rem;
        padding-bottom: 0.4rem;
        // margin-left: auto;
        margin-top: 0;
        font-weight: 500;

        @include tablet {
            width: 75%;
            padding-bottom: 0.5rem;
        }
    }

    &__item {
        margin-top: 1rem;
        margin-top: 0.25rem;
        color: $tech-writing;
    }




}

.technology-list {
    flex-basis: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    @include tablet {
        flex-direction: row;
    }

    &__item {

        font-size: 0.8rem;
        height: 0.75rem;
        background-color: $color-k-low-op;
        box-sizing: border-box;
        line-height: 0.75rem;
        font-weight: 500;
        border-radius: 0.5rem;
        padding: 0.5rem;
        margin: 0.25rem 0.25rem;
        box-sizing: border-box;
        height: 1.75rem;
        flex-grow: 2;
        flex-basis: 30%;
        text-transform: capitalize;

        @include tablet {
            // flex-grow: unset;
            min-width: 15.25%;
            flex-basis: unset;
        }

        &--all {
            font-size: 1.17rem;
            font-weight: 700;
            background-color: transparent;
            min-width: 2.5rem;
        }

        &--apollo {
            color: $technology-dark;
            background-color: $apollo-label;

        }

        &--aws {
            color: $technology-dark;
            background-color: $aws-label;
        }

        &--express-js {
            color: $technology-dark;
            background-color: $express-js-label;
        }

        &--graphql {
            color: $technology-dark;
            background-color: $graphql-label;
        }

        &--javascript {
            color: $technology-dark;
            background-color: $javascript-label;
        }

        &--knex-js {
            color: $technology-dark;
            background-color: $knex-js-label;
        }

        &--material-ui {
            color: $technology-dark;
            background-color: $material-ui-label;
        }

        &--mysql {
            color: $technology-dark;
            background-color: $mysql-label;
        }

        &--next-js {
            color: $technology-dark;
            background-color: $next-js-label;
        }

        &--node-js {
            color: $technology-dark;
            background-color: $node-js-label;
        }

        &--react {
            color: $technology-dark;
            background-color: $react-label;
        }

        &--sass {
            color: $technology-dark;
            background-color: $sass-label;
        }

        &--typescript {
            color: $technology-dark;
            background-color: $typescript-label;
        }

    }
}