@use '../../styles/colours.scss' as *;
@use '../../styles/mixins.scss' as *;

@use '../../styles/conversions.scss' as *;
@use '../../styles/global.scss' as *;


.p-homepage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    // transition-duration: 1s;
    // transition-timing-function: cubic-bezier(.22, .61, .36, 1);

    // &:hover {
    //     transform: scale(0.8) translateZ(50px);
    // }

    @include desktop {
        flex-direction: row;
    }

    &__group {

        height: 100%;

        @include desktop {
            width: 40%;
            max-width: 25rem;

        }
    }

    .hp-card {
        margin: 1rem;
        margin-bottom: 0.5rem;
        padding: 1rem;
        color: $color-b;
        border: thin solid $color-g;
        border-radius: 0.5rem;
        text-align: justify;

        &--contributions {
            padding: 0;
            margin: 1rem;
        }

        &__home {
            color: $color-c;
            padding: 0.6rem;
            line-height: 1.5rem;
            font-size: 1.17rem;
            text-align: center;
            font-weight: 700;
        }

        &__links {
            display: flex;
            gap: 1rem;
            justify-content: center;
            flex-direction: row;
            font-weight: 700;

        }

        &__link,
        &__link--home {
            color: $color-a;
            text-decoration: none;
            transition: all 0.25s ease-in-out;

            &:hover {
                color: $color-l;
            }
        }
    }

    .about-philip {
        width: 25%;
        margin-left: 0;
        color: $color-a;
        font-size: small;
        height: 100%;

        display: none;

        &__title {
            margin-top: 0;
        }

        &__paragraph {
            margin-top: 1rem;
        }

        @include desktop {
            display: flex;
            flex-direction: column;
        }
    }

    &__technologies {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
    }

    &__title {
        font-size: 1.17rem;
        margin: 1rem 0;
        height: 1rem;

    }

}

.hidden-mobile {
    display: none;

    @include tablet {
        display: flex;
    }
}