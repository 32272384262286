@use './colours.scss' as *;
@use './fonts.scss' as *;

* {
    font-family: 'Inter';
    padding: 0;
    margin: 0;
}

html,
body {
    height: 100%;
    min-height: 100%;
}

body {
    // background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(242, 253, 255, 1) 100%);
      overflow-y: scroll;
}

#root {
    height: 100%;
}

.writing-block {
    // background-color: rgb(248, 248, 248);
    color: rgb(61, 61, 61);
    // width: 100%;
    // padding: 4rem;
}

.letter {
    transition: all 0.25s cubic-bezier(0.48, 2.06, 0.51, 0.35);
}

// animatedButton

@keyframes rippleEffect {
    from {
        transform: scale(0);
        opacity: 1;
    }

    to {
        transform: scale(3);
        opacity: 0;
    }
}

.ripple {
    opacity: 0;
    position: absolute;
    width: 12rem;
    height: 12rem;
    border-radius: 50%;
    background: radial-gradient(circle, rgba(255, 0, 244, 0.31) 0%, rgba(4, 189, 4, 0) 47%, rgba(32, 168, 31, 0) 53%, rgba(255, 0, 244, 0.30500122412246145) 100%);
    background: rgba(247, 0, 255, 0.062);
    animation: rippleEffect .5s ease-in;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
}

@keyframes glow {
    0% {
        color: $color-a;
    }

    50% {
        color: $color-d;
    }

    100% {
        color: $color-a;
    }
}

@keyframes bright-glow {
    0% {
        color: $color-a;
    }

    50% {
        color: $color-l;
    }

    100% {
        color: $color-a;
    }
}

// components shared

.component-header {
    &__title {
        color: $projects-header;
        font-size: 1.2rem;
        padding: 0 2.5rem 0.5rem 0;
        text-align: right;
        text-transform: uppercase;
    }
}