@use '../../styles/colours.scss' as *;
@use '../../styles/conversions.scss' as *;
@use '../../styles/mixins.scss' as *;

.c-contributions {
    width: 100%;
    height: 18rem;
    border-radius: 0.5rem;
    overflow: hidden;
    z-index: 2;

    @include tablet {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.c-projects .c-contributions {
    border: thin solid $color-g;
    box-sizing: border-box;
    height: auto;
    padding: 0.5rem;
    margin-bottom: 1rem;
    margin: 0.5rem 0 1rem 0;
}

.date,
.count {
    padding: 1rem;
}

.css-l0h214-MuiResponsiveChart-container {
    margin-top: 0;

    @include tablet {
        margin-top: -4rem;
    }
}

.react-calendar-heatmap text {
    font-size: 10px;
    fill: #aaa;
}

.react-calendar-heatmap .react-calendar-heatmap-small-text {
    font-size: 5px;
}

.react-calendar-heatmap rect:hover {
    stroke: #555;
    stroke-width: 1px;
}

.react-calendar-heatmap .color-empty {
    fill: #eeeeee;
}

.react-calendar-heatmap .color-filled {
    fill: #8cc665;
}

.react-calendar-heatmap .color-github-0 {
    fill: #eeeeee;
}

.react-calendar-heatmap .color-github-1 {
    fill: #d6e685;
}

.react-calendar-heatmap .color-github-2 {
    fill: #8cc665;
}

.react-calendar-heatmap .color-github-3 {
    fill: #44a340;
}

.react-calendar-heatmap .color-github-4 {
    fill: #1e6823;
}

.react-calendar-heatmap .color-github-5 {
    fill: #19581e;
}

.react-calendar-heatmap .color-github-6 {
    fill: #113b14;
}